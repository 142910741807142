.collapse {
    transition: width 1s ease 0s;
    -webkit-text-size-adjust: 100%;
}

.collapse .collapse .show .width {
    transition: width 2s ease 1s;
}

.collapse .collapse .width {
    transition: width 2s ease 1s;
}

.dropdown-toggle .btn .btn-outline-secondary {
    padding: 0;
}

.dropdown-toggle {
    padding: 0 6px;
    border-color: lightgray;
}

.table td,
.table th {
    padding: 5px;
}

.table-hover tbody tr:hover {
    /* font-weight: bold; */
    background-color: #e2f5fa;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #216ba5;
    background-color: #f4f4f4;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: none;
    font-size: 14px;
    /* font-weight: bold; */
}

.nav-tabs .nav-link {
    margin-bottom: -2px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    padding: 5px 10px 7px 10px;
    font-size: 12px;
    height: fit-content;
}

.my-modal {
    min-width: 90%;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 0;
}

.form-check-input {
    bottom: 5px;
}

.samples_modal {
    min-width: 60%;
    text-align: justify;
    /* margin-top: 10px; */
    margin-bottom: 0;
}