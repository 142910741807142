.statisticTable tbody td,
.statisticTable tbody th {
    padding: 0.15rem;
}

.edit_result_modal {
    min-width: 40%;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 0;
}