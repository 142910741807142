.my-modal {
    min-width: 50%;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 0;
}

.react-datepicker__input-container .form-control-date-pick-edit-case {
    height: 26px;
    border: solid 1px #c9d0d6;
}

.react-datepicker__input-container .form-control-date-pick-edit-case ::after,
 ::before,
 ::content,
 ::marker,
 ::selection {
    height: 26px;
    border: solid 1px #c9d0d6;
}