.pages-container {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 25px;
    align-items: center;
}

.a-page-of-paginator {
    border: 1px solid #1171b8;
    color: #1171b8;
    padding: 1px 8px 1px 8px;
    outline: none;
    transition: .5s;
}

.a-page-of-paginator:hover {
    color: #ffffff;
    background-color: #1171b8;
}

.page-link {
    font-size: 12px;
    padding: 6px 9px;
    border-radius: 0;
}

.page-itemm {
    border-radius: 0 !important;
}


/* .page-link {} */


/* border-radius: 0;
border-radius: 0; */