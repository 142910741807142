.datePicker {
    display: "flex";
    width: calc(100% - 40px);
}

.lala {
    flex-wrap: inherit !important;
}

.common_styles {
    font-size: 12px;
    border-radius: 0;
    font-family: 'Raleway', sans-serif
}


/* ----------------------typeahead styles---------------------- */

.rbt-input-main {
    /* font-size: 12px;
    border-radius: 0;
    font-family: 'Raleway', sans-serif */
}


/* ----------------------typeahead styles---------------------- */

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    font-size: 12px;
    border-radius: 0;
    padding: "10px"
}

.react-datepicker-popper {
    z-index: 1999!important;
}

.dropdownEvidence .dropdown-toggle {
    border: none;
    padding: 2px 5px;
    border-radius: 10px;
    float: right
}