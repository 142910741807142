.pro-sidebar {
    width: 415px!important;
}

.pro-sidebar.collapsed {
    width: 0;
    min-width: 0;
}

.pro-sidebar>.pro-sidebar-inner {
    background-color: #f5f5f5;
    /* background-color: #444b59; */
    z-index: 2;
    box-shadow: 2px 0px 20px rgb(0 0 0 / 55%)
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 12px;
    background-color: #444B59;
    margin: 0px 10px
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background-color: #FFF!important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding: 10px!important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
    padding-top: 0;
    padding-bottom: 0;
}

.pro-sidebar .pro-menu a:before {
    position: inherit
}

.pro-sidebar-inner {
    /* Your custom styles here */
    background: white!important;
}

.pro-sidebar.collapsed {
    width: 0!important;
    min-width: 0!important;
}

.pro-sidebar.pro-inner-list-item {
    background-color: "auto";
}

.pro-sidebar .pro-menu a:before {
    position: inherit!important;
}

sidebar_btn {
    background: #ff4e58;
    display: inline;
    position: fixed;
    color: #fff;
    margin-top: 30px;
    padding: 0em .6em .2em .6em;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate( 90deg);
    transform: rotate( 90deg);
    -ms-transform: bottom left 0;
    -webkit-transform: bottom left 0;
    transform-origin: bottom left 0;
    cursor: pointer;
    transition: left .8s;
    left: 25%;
    z-index: 111;
}


/* estilos de radio button */

.form-check-label {
    vertical-align: -webkit-baseline-middle
}


/* estilos de radio button */


/* estilos de range slider */

.slider .tooltip.in {
    opacity: 1;
}

.slider .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.slider .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.slider .tooltip.top {
    padding: 5px 0;
}


/* estilos de range slider */