.venntooltip {
    position: absolute;
    text-align: center;
    width: 128px;
    background: #333;
    color: #ddd;
    border: 0px;
    border-radius: 0;
    opacity: 0;
    /* height: 25px; */
    /* padding: 2px; */
}