#tooltip-top>.tooltip-inner {
    background-color: rgba(68, 75, 89, 0.85);
    font-size: 11px;
    color: #fff;
    border-radius: 0px;
    /* border: 1px solid #062e56; */
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: rgba(68, 75, 89, 0.85);
}