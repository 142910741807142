.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background: #ff4e58 url(../../imgs/login-bg.gif) no-repeat right bottom;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 13vh;
    /* justify-content: center; */
    /* color: white; */
}

.login-title {
    background: url(../../imgs/logo.png) no-repeat left top;
    text-indent: 130%;
    overflow: hidden;
    margin-bottom: 20px;
}

.logo-sm {
    background: url(../../imgs/bitgenia.png) no-repeat left top;
    display: inline-block;
    width: 94px;
    height: 18px;
    text-indent: 200px;
    overflow: hidden;
    margin: 6px 0 -9px 0;
}

.login-footer {
    color: #fff;
    margin-top: 5px;
    position: relative;
    text-align: center;
    font-size: small;
}

.App-link {
    color: #61dafb;
}