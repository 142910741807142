body {
	font-size: 15px;
	font-weight: 400;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-family: Raleway, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dropdown-menu {
	font-size: 13px;
	z-index: 13;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
	font-size: 12px;
	border-radius: 0;
}

.modal-content {
	border: none;
}

.popover {
	z-index: 100;
}

.card {
	border-radius: 0px;
}

::-webkit-scrollbar {
	width: 12px;
	height: 9px;
	background-color: #F5F5F5;
	overflow-x: auto;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: #F5F5F5;
	overflow-x: auto;
}

::-webkit-scrollbar-thumb {
	border-radius: 0px;
	/* box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
	/* background-color: #80898d; */
	background: #a4acaf;
	overflow-x: auto;
}

.my-modal {
	min-width: 60%;
	text-align: justify;
	margin-top: 10px;
	margin-bottom: 0;
}

.my-modal-85 {
	min-width: 90%;
	text-align: justify;
	margin-top: 10px;
	margin-bottom: 0;
}

.my-modal-50 {
	min-width: 50%;
	text-align: justify;
	margin-top: 10px;
	margin-bottom: 0;
}

.custom-file-label {
	height: 32px;
	border-radius: 0%;
	font-size: 14px;
}

.custom-file {
	margin: 5px 4px 1px
}