.navbar {
    background: #ff4e58 !important;
    padding: 2px;
    justify-content: space-between;
    z-index: 6;
    position: fixed;
    width: 100%;
    /* margin-top: -10px; */
}

.secondary-navbar {
    background: #444b59;
    height: 28px;
    padding: 0 15px;
    position: fixed;
    width: 100%;
    margin-top: 54px;
    z-index: 5;
    /* box-shadow: rgb(0 0 0 / 43%) 0px 5px 15px */
}

.navbar2 {
    /* background: #ff4e58 url(../../imgs/logo.png) no-repeat !important; */
    background: url(../../imgs/logo.png) no-repeat;
    width: 180px;
    /* margin: 2px; */
    height: 35px
}

.navbar-secondary {
    /*qwdqwdqwdqdqwdqwdqw*/
    background: #444b59;
    height: 30px;
    padding: 0 15px;
    margin-top: -25px;
}

.navbar-secondary .nav-pills {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}

.navbar-secondary .nav-pills a {
    margin: 0 15px;
    color: #ccc;
    border-radius: 0;
    padding: 6px 3px 1px 3px;
}

.navbar-secondary .nav-pills a:hover,
.navbar-secondary .nav-pills a:focus {
    background: none;
    color: #fff;
}

.navbar-secondary .nav-pills .active a {
    background: none;
    color: #fff;
    border-bottom: 3px solid #fafafa;
}

.navbar-secondary .nav-pills .active a:hover,
.navbar-secondary .nav-pills .active a:focus {
    background: none;
    color: #fff;
}

@media (max-width: 767px) {
    .navbar-secondary .nav-pills a {
        margin: 0 10px 0 0;
    }
}