/* .selectTable tbody tr:hover td,*/

.table-responsive-md {
    /* overflow-y: hidden; */
    overflow: revert;
    /* overflow-x: clip; */
}

@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
    }
}

@media (min-width: 768px) {
    .table-responsive {
        overflow: inherit;
    }
}

.myTable tbody tr:hover td,
.myTable tbody tr:hover th {
    background-color: #E5F5FD;
    /* background-color: #5FDE5F; */
}

.myTable tbody .processingTr:hover td,
.myTable tbody .processingTr:hover th {
    /* background-color: #E5F5FD; */
    background-color: #efefef;
}


/* ===============================selectTable=============================== */

.table-responsive-xs {
    width: 100%;
}

.table-responsive-sm {
    width: 100%;
}

.table-responsive-md {
    width: 100%;
}

.table-responsive-lg {
    width: 100%;
}

.table-responsive-xl {
    width: 100%;
}

.selectTable tbody tr:hover {
    box-shadow: 0px 0px 12px 0px rgba(85, 124, 155, 0.35);
}

.selectTable tbody tr:hover td,
.selectTable tbody tr:hover th {
    /* transform: scale(1.2); */
    background-color: #E5F5FD;
    max-width: 100%;
}

.selectTable tbody .processingTr:hover td,
.selectTable tbody .processingTr:hover th {
    background-color: #efefef;
}

.selectTable tbody tr:hover {
    /* border-left: solid 5px #009EEA;
    border-right: solid 5px #009EEA; */
}

.selectTable tbody .processingTr:hover {
    /* border-left: solid 5px gray;
    border-right: solid 5px gray; */
}

.selectTable td,
.selectTable th {
    padding: 0.25rem;
    /* word-break: break-word; */
}


/* ==============selectTable-stripped============== */

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(1, 1, 1, 0.025);
}

.table-striped>tbody>tr:hover:nth-child(odd)>td,
.table-striped>tbody>tr:hover:nth-child(odd)>th {
    background-color: #E5F5FD;
    z-index: 22;
}


/* ==============selectTable-stripped============== */


/* ===============================selectTable=============================== */

.filter-component {
    margin: 10px 20px 0px;
    position: fixed;
    z-index: 4;
    right: 0;
    padding: 0;
    width: -webkit-fill-available;
}

a {
    color: #009EEA
}